<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-menu-button></ej-menu-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<div class="container-fluid" v-if="spinner">
				<div class="row d-flex justify-content-center">
					<div class="default-spinner">
						<div class="default-spinner-inner">
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid" v-else>
				<div class="first-block">
					<div class="container-fluid">
						<div class="row">
							<h4>Olá Guilherme,</h4>
						</div>

						<div class="row">
							<p>Seja bem-vindo ao novo sistema de pedidos da <strong>{{ _.get(company, 'name') ? company.name.ucwords() : '' }}</strong>. Um novo visual, uma nova experiência! 😄</p>
						</div>
					</div>
				</div>

				<div class="block gray">
					<div class="container-fluid">

						<div class="row">
							<h5>👉🏻 PWA (Aplicativo da Web Progressivo)</h5>
						</div>

						<div class="row align-items-center">
							<div class="col-12 col-lg-3">
								<img class="img-fluid" src="/img/undraw_mobile_devices_k1ok.svg" alt="">
							</div>

							<div class="col-12 col-lg-4">
								<p>
									Agora o sistema é também um aplicativo, que pode ser instalado no seu <strong>smartphone ou tablet</strong>.
									Isso é possível graças ao PWA...
								</p>
							</div>
						</div>
					</div>
				</div>

				<div class="block">
					<div class="container-fluid">
						<div class="row">
							<h5>👉🏻 API (Interface de Programação de Aplicativos)</h5>
						</div>

						<div class="row align-items-center">
							<div class="col-12 col-lg-3">
								<img class="img-fluid" src="/img/undraw_real-time_sync_o57k.svg" alt="">
							</div>

							<div class="col-12 col-lg-4">
								<p>
									Agora o sistema pode ser integrado facilmente por softwares de terceiros, através da
									nossa API.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ej-content>
	</ej-page>
</template>

<script>
	import EjPage from "@/components/EjPage";
	import EjMobileHeader from "@/components/EjMobileHeader";
	import EjToolbar from "@/components/EjToolbar";
	import EjButtons from "@/components/EjButtons";
	import EjMenuButton from "@/components/EjMenuButton";
	import EjTitle from "@/components/EjTitle";
	import EjContent from "@/components/EjContent";
	import {exibeErro} from "@/helpers";

	export default {
		name: "Home",

		data() {
			return {
				company: {},
				loadingCompany: false,
			}
		},

		computed: {
			spinner() {
				return this.loadingCompany;
			}
		},

		mounted() {
			const vm = this;

			vm.loadingCompany = true;
			this.$store.dispatch(`people/get`, 1)
				.then(function (response) {
					vm.company = Object.assign(vm.company, response.data);
				})
				.catch(function (error) {
					// handle error
					exibeErro(error);
				})
				.then(function () {
					// always executed
					vm.loadingCompany = false;
				});
		},

		components: {
			EjPage,
			EjMobileHeader,
			EjToolbar,
			EjButtons,
			EjMenuButton,
			EjTitle,
			EjContent
		}
	}
</script>

<style scoped>
	img {
		margin-bottom: 15px;
	}

	.block.gray {
		background: #f7f7f7;
	}

	.first-block {
		padding: 0 30px 20px 0;
		border-radius: 20px;
	}

	.block {
		padding: 40px 30px;
		border-radius: 20px;
	}

	.block p {
		font-size: 18px;
	}

	/deep/ .ej-content {
		padding: 15px 20px;
	}

	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		/deep/ .ej-content {
			padding: 30px;
		}
	}
</style>
